import { productImgs } from "contains/fakeData";
import productVariantImg2 from "images/products/v2.jpg";
import productVariantImg3 from "images/products/v3.jpg";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import productVariantImg6 from "images/products/v6.jpg";
//
import productSport1 from "images/products/sport-1.png";
import productSport2 from "images/products/sport-2.png";
import productSport3 from "images/products/sport-3.png";
import productSport4 from "images/products/sport-4.png";
import productSport5 from "images/products/sport-5.png";
import productSport6 from "images/products/sport-6.png";
import productSport7 from "images/products/sport-7.png";
import productSport8 from "images/products/sport-8.png";

//

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: number;
  name: string;
  color?: string;
  cut?: string;
  slug: string;
  price: number;
  image: string;
  description: string;
  category: string;
  tags: string[];
  link: "/produkt/";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  size?:string;
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
  product_type: string;
  product_code: string;
  selectedCut?: string;
  count?:number;

}

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Čierna",
    thumbnail: productVariantImg6,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Biela",
    thumbnail: productVariantImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Oranžová",
    thumbnail: productVariantImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Modrá",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Prírodná",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Fialová",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Žltá",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Oranžová",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Modrá",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Zelená",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

export const PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Červená mikina",
    slug: "cervena-mikina",
    description: "Brown cockroach wings",
    price: 74,
    image: productImgs[16],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/produkt/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: undefined,
    product_code: "",
    product_type: "",
  },
  {
    id: 2,
    name: 'Zelený batoh',
    slug: "zeleny-batoh",
    description: "Zeleny batoh",
    price: 68,
    image: productImgs[1],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/produkt/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: undefined,
    product_code: "",
    product_type: "",
  },
  {
    id: 3,
    name: "Modrý batoh",
    slug: "modry batoh",
    description: "Modrý batoh",
    price: 132,
    image: productImgs[15],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/produkt/",
    variants: DEMO_VARIANTS,
    variantType: "image",

    allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
    product_code: "",
    product_type: "",
  },
  {
    id: 4,
    name: "Ružový batoh",
    slug: "Ružový batoh",
    description: "Ružový batoh",
    price: 28,
    image: productImgs[3],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/produkt/",
    status: "Sold Out",
    product_code: "",
    product_type: "",
  },
  {
    id: 5,
    name: "Leather Gloves",
    slug: "Rey Nylon Backpack",
    description: "Perfect mint green",
    price: 42,
    image: productImgs[4],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link: "/produkt/",
    product_code: "",
    product_type: "",
  },
  {
    id: 6,
    name: "Hoodie Sweatshirt",
    slug: "Rey Nylon Backpack",
    description: "New design 2023",
    price: 30,
    image: productImgs[5],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/produkt/",
    product_code: "",
    product_type: "",
  },
  {
    id: 7,
    name: "Wool Cashmere Jacket",
    slug: "Rey Nylon Backpack",
    description: "Matte black",
    price: 12,
    image: productImgs[8],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/produkt/",
    status: "New in",
    product_code: "",
    product_type: "",
  }
];

export const SPORT_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Mastermind Toys",
    slug: "Rey Nylon Backpack",
    description: "Brown cockroach wings",
    price: 74,
    image: productSport1,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/produkt/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    sizes: ["XS", "S", "M", "L", "XL"],
    allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
    product_code: "",
    product_type: "",
  },
  {
    id: 2,
    name: "Jump Rope Kids",
    slug: "Rey Nylon Backpack",
    description: "Classic green",
    price: 68,
    image: productSport2,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/produkt/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
    product_code: "",
    product_type: "",
  }
];
